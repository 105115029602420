import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Shop" />

    <div className="custom-page-wrapper"></div>
  </Layout>
)

export default ContactPage
